<template>
  <LineChart></LineChart>
</template>

<script>

  import LineChart from "@/components/LineChart";

  export default {
    name: 'HomeView',
    components: {
      LineChart,

    },
  }
</script>
