<template>
    <v-overlay :value="isLoading" z-index="999">
        <v-progress-circular
                indeterminate
                size="64"

        ></v-progress-circular>
    </v-overlay>
</template>

<script>
    import {EventBus} from '@/helpers/EventBus'
    export default {
        name: "LoadingSpinner",
        data:()=>(
            {
                isLoading:false
            }
        ),
        created() {
            EventBus.$on("toggle",(status)=>{
                this.isLoading=status
            })
        }
    }
</script>
