import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') || '',
        userLevel:localStorage.getItem('user_level')||'20',
        user_name:localStorage.getItem('user_name')||''


    },
    getters: {
        isLoggedIn: state => !!state.token,
        isAdmin: state=>{
            if(state.userLevel=='10'){
                return true
            }else {
                return false
            }
        },
        getUsername:state=>state.user_name

    },
    mutations: {
        auth_success(state, credential) {
            state.token = credential.token
            state.userLevel=credential.user_level
            state.user_name=credential.user_name

            localStorage.setItem('token', credential.token)
            localStorage.setItem('user_level', credential.user_level)
            localStorage.setItem('user_name', credential.user_name)
        },
        async logout(state) {
            state.token = ''
            state.userLevel=false
            state.user_name=''
            localStorage.removeItem('token')
            await router.push({path: '/login'})
        }
    },
    actions: {
        login({commit}, credential) {
            commit('auth_success', credential)
        },
        logout({commit}) {
            commit('logout')
        }

    },
    modules: {}
})
