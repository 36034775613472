<template>
  <v-app>
    <v-navigation-drawer
        v-model="drawer"
        app
        width="200"
        color="blue-grey darken-1"
        dark

    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            AIOS ADMIN
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list  nav>

        <v-list-item dense
                     v-for="(item,i) in menu_item" :key="i"

                     :to="item.to"
                     v-show="ShowItem(item)"
                     active-class="orange accent-3"

        >
          <v-list-item-icon class="mr-3">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dense color="blue-grey darken-1" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>All In One Services</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="profileDialog=true"  text><v-icon>mdi-account-key-outline</v-icon> ປ່ຽນລະຫັດ</v-btn>
      <v-btn @click="Logout" color="error" icon ><v-icon>mdi-power</v-icon></v-btn>
      <v-dialog v-model="profileDialog" max-width="400px" :retain-focus="false" persistent>
        <v-card>
          <v-card-title class="text-h6">ປ່ຽນລະຫັດຜ່ານ</v-card-title>
          <v-card-text>
            <v-alert type="error" dismissible v-model="invalid_Pass" v-if="invalid_Pass">ລະຫັດຜ່ານເກົ່າບໍ່ຖືກຕ້ອງ...</v-alert>
            <v-form ref="update_profile_form">
              <v-text-field label="ຊື່ຜູ້ໃຊ້" :value="$store.getters.getUsername"
                            disabled></v-text-field>
              <v-text-field type="password" label="ລະຫັດຜ່ານເກົ່າ" v-model="update_profile.old_pass"
                            :rules="password_rules"></v-text-field>
              <v-text-field type="password" label="ລະຫັດຜ່ານໃໝ່" v-model="update_profile.new_pass"
                            :rules="password_rules"></v-text-field>

            </v-form>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="profileDialog=false">ປິດ</v-btn>
            <v-btn color="success" @click="UpdateProfile">ບັນທຶກ</v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>

    <v-main>
      <div class="pa-3" >
        <router-view></router-view>
      </div>

    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "MainLayout",
  data: () => ({
    drawer: null,
    menu_item: [
      {title: 'ໜ້າຫຼັກ', icon: 'mdi-view-dashboard', to: '/',onlyAdmin:false},
      {title: 'ຜູ້ໃຊ້ລະບົບ', icon: 'mdi-account-key', to: '/user',onlyAdmin:true},
      {title: 'ຄູ່ຮ່ວມທຸລະກິດ', icon: 'mdi-account-multiple-outline', to: '/partner',onlyAdmin:false},
      {title: 'ຮູບແບນເນີ', icon: 'mdi-view-compact-outline', to: '/banner',onlyAdmin:false},
      {title: 'ໃບສະເໜີລາຄາ', icon: 'mdi-file-sign', to: '/quote',onlyAdmin:false},
      {title: 'ລາຍຮັບ', icon: 'mdi-currency-usd', to: '/sale'},
      {title: 'ຮຽກສີນທົດແທນ', icon: 'mdi-alert-outline', to: '/claim',onlyAdmin:false},
      {title: 'ລາຍງານລາຍຮັບ', icon: 'mdi-chart-line', to: '/salereport',onlyAdmin:false},
      {title: 'ລາຍງານເຄຼມ', icon: 'mdi-chart-pie', to: '/claimreport',onlyAdmin:false},
  

    ],
    profileDialog:false,
    update_profile:{
      old_pass:'',
      new_pass:''
    },
    password_rules: [v => (v && v.length > 5)],
    invalid_Pass:false

  }),
  methods:{
    Logout:async function(){
      await this.$store.dispatch('logout')
    },
    ShowItem(item){
      const isAdmin=this.$store.getters.isAdmin
      if(isAdmin==true){
        return true
      }else{
       return  !isAdmin && !item.onlyAdmin
      }
    },

    UpdateProfile:async function(){
      if(!this.$refs.update_profile_form.validate()){
        return
      }
        try {
          const res=await window.axios.post('user/profile',this.update_profile)

          if(res.data.status=='S'){
            await this.$store.dispatch('logout')
          }else{
            this.invalid_Pass=true
          }
        }catch (e){
          console.log(e)
        }
    }

  }
}
</script>
