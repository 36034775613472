<template>
  <div class="chart">
    <v-row class="pt-4">
      <v-col cols="12" lg="2" md="3" sm="4" class="py-0">
        <v-text-field
            outlined
            dense
            v-model="year"
            label="ປີ"
            readonly
            prepend-inner-icon="mdi-minus"
            append-icon="mdi-plus"
            @click:prepend-inner="decrement"
            @click:append="increment"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <LineChartGenerator
            v-if="loaded"
            :options="chartOptions"
            :data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        />
      </v-col>
    </v-row>
  </div>


</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
)

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        datasets: [
          {
            label: 'ຍອດຂາຍ',
            backgroundColor: '#33ff40',
            lineTension:0.4,
            data: [0, 0, 0, 0, 0, 0, 0,0,0,0,0,0]
          },
          // {
          //   label: 'Data Two',
          //
          //   backgroundColor: '#f87979',
          //   lineTension:0.4,
          //   data: [0, 0, 0, 0, 0, 0, 0,0,0,0,0,0]
          // }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,

      },
      loaded:false,
      year:(new Date()).getFullYear()

    }
  },
  mounted() {
    this.getData()
  },
  watch:{
    year(){
      this.getData()
    }
  },
  methods:{
    getData:async function(){
      this.loaded=false
      try {
        const res=await window.axios.get('dashboard',{params:{year:this.year}})
        const {sale_report}=res.data
        this.chartData.datasets[0].data=sale_report
        this.loaded=true

      }catch (e){
        console.log(e)
      }
    },
    decrement() {
      this.year--
    },
    increment() {
      this.year++
    }
  }
}
</script>
