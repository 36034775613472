import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MainLayout from "@/views/MainLayout";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MainLayout,
    meta:{
      requiresAuth:true
    },
    children:[
      {
        path:'/',
        name: 'home',
        component:HomeView
      },
      {
        path:'/user',
        name: 'user',
        component: () => import( '../views/user/UserView')
      },
      {
        path:'/user/create',
        component: () => import( '../views/user/UserCreate')
      },
      {
        path:'/user/update/:id',
        component: () => import( '../views/user/UpdateView')
      },
      {
        path:'/banner',
        component: () => import( '../views/banner/bannerView')
      },
      {
        path:'/banner/create',
        component: () => import( '../views/banner/uploadView')
      },
      {
        path:'/quote',
        component: () => import( '../views/quote/QuoteView')
      },
      {
        path:'/partner',
        component: () => import( '../views/partner/partnerView')
      },
      {
        path:'/sale',
        component: () => import( '../views/sale/saleView')
      },

        //Claim section
      {
        path:'/claim',
        component: () => import( '../views/claim/claimView')
      },
      {
        path:'/claim/create',
        component: () => import( '../views/claim/claimForm')
      },
      {
        path:'/claim/detail/:id',
        component: () => import( '../views/claim/claimDetail')
      },
      {
        path:'/claim/review/:id',
        component: () => import( '../views/claim/claimReview')
      },
      {
        path:'/claimreport',
        component: () => import( '../views/claim/claimReport')
      },
      {
        path:'/salereport',
        component: () => import( '../views/sale/saleReport')
      }
    ]
  },
  {
    path:'/login',
    name: 'login',
    component: () => import( '@/views/Login')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to,from,next)=>{
  if(to.matched.some(record=>record.meta.requiresAuth)){
    if(store.getters.isLoggedIn){
      next()
      return
    }
    next('/login')
  }else {
    next()
  }
})

export default router
