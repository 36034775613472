<template>
  <div>
    <LoadingSpinner></LoadingSpinner>
    <router-view/>
  </div>

</template>

<script>

import LoadingSpinner from "@/components/LoadingSpinner";
export default {
  name: 'App',
  components: {LoadingSpinner},
  data: () => ({
    //
  }),
};
</script>
